// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Url from '../configs/api'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  return item ? JSON.parse(item) : {}
}

// ** Async Thunk for Logout API
export const logoutUser = createAsyncThunk('authentication/logout', async () => {
  try {
    // Get access token from Redux state
    const accessToken = JSON.parse(localStorage.getItem('accessToken'))

    // Call the logout API using GET method
    if (accessToken) {
     await fetch(`${Url.baseURL}/auth/logout`, {
        method: 'GET', // Change to GET method
        headers: {
          'Content-Type': 'application/json', // Ensure content type is set
          Authorization: `Bearer ${accessToken}` // Pass the token in Authorization header
        }
      })
        window.location.href = '/login'

    }
  } catch (error) {
    console.error('Logout API failed:', error)
  }
})

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions
export default authSlice.reducer
