// import axios from 'axios'

// const instance = axios.create({
//   baseURL: 'https://127.0.0.1:5000/api/v1/' // Set your base URL here
// })


const Url = {
  // baseURL: "http://127.0.0.1:5001/api/v1",
  // baseURLImg: "http://127.0.0.1:5001/uploads",
  // baseURLInv: "http://127.0.0.1:5001/uploads/invoices"
  baseURL: "https://node.rentxs.com/api/v1",
  baseURLImg: "https://node.rentxs.com/uploads",
  baseURLInv: "https://node.rentxs.com/uploads/invoices"
}

export default Url